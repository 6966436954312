<template>
  <main>
    <Loading v-if="isLoading"></Loading>
    <div v-else>
      <h2 class="text-2xl font-bold page-title">Fraud</h2>
      <div class="bg-blue-100 p-5">
        <p>
          Please note that all downloads are in .txt formats. You will need to
          change it to .csv format after downloading it. Merci.
        </p>
      </div>
      <div class="flex justify-end items-end my-5">
        <button
          @click="downloadCSV"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-600 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Download CSV
        </button>
      </div>
      <div class="flex items-center my-5">
        <input
          type="text"
          class="inline-block px-3 ring-2 outline-none ring-blue-500 rounded-sm w-80 text-sm py-2"
          name=""
          placeholder="Search by Business Name or Email Address"
          id=""
          v-model="search"
        />
        <button
          @click="handleSearch"
          class="block uppercase shadow ml-2 text-sm bg-blue-900 text-white hover:bg-blue-700 focus:shadow-outline focus:outline-none py-3 px-8 rounded"
        >
          Search
        </button>
      </div>
      <div class="my-5 flex justify-end items-end">
        <button
          @click="fetchAll"
          class="block uppercase shadow ml-3 text-xs hover:bg-blue-700 focus:shadow-outline focus:outline-none hover:text-white py-3 px-8 rounded"
          :class="
            notFraudulentFlag === false && fraudulentFlag === false
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          All
        </button>
        <button
          @click="notFraudulent"
          class="block uppercase shadow ml-3 hover:bg-blue-700 hover:text-white focus:shadow-outline focus:outline-none text-black text-xs py-3 px-8 rounded"
          :class="
            notFraudulentFlag === true
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          Not Fraudulent
        </button>
        <button
          @click="fraudulent"
          class="block uppercase shadow ml-3 hover:bg-blue-700 focus:shadow-outline hover:text-white focus:outline-none text-black text-xs py-3 px-8 rounded"
          :class="
            fraudulentFlag === true
              ? 'bg-blue-700 text-white'
              : 'bg-white text-blue-700'
          "
        >
          Fraudulent
        </button>
      </div>

      <div class="mt-14" style="height: 600px; overflow: auto">
        <table class="shadow-lg bg-white w-full relative border-collapse">
          <tr>
            <th
              class="text-l p-3 text-left"
              colspan="14"
              style="background: #dee6f2"
            >
              Merchant Information
            </th>
          </tr>
          <tr>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Business Name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Merchant name
            </th>
            <th
              class="bg-blue-100 text-sm whitespace-nowrap text-left px-8 py-2"
            >
              Phone Number
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Email Address
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Flag Reason
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Account Status
            </th>
            <th
              class="bg-blue-100 text-sm border whitespace-nowrap text-left px-8 py-2"
            >
              Action
            </th>
          </tr>
          <tr v-for="(account, i) in filterAccounts" :key="i">
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.business_name }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.merchant_name }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.phone_number }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.email }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">N/A</td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              {{ account.is_fraud ? "Fraudulent" : "Not Fraudulent" }}
            </td>
            <td class="border px-8 py-3 whitespace-nowrap text-xs">
              <button
                v-if="!account.is_fraud"
                @click="markAsFraudulent(account.business_id, true)"
                class="block uppercase shadow ml-3 hover:bg-white bg-red-200 focus:shadow-outline focus:outline-none text-black text-xs font-bold py-3 px-8 rounded"
              >
                Mark as Fraudulent
              </button>
              <button
                @click="markAsFraudulent(account.business_id, false)"
                v-else
                class="block uppercase shadow ml-3 bg-blue-700 hover:bg-blue-700 focus:shadow-outline focus:outline-none text-white text-xs py-3 px-8 rounded"
              >
                Mark as Not Fraudulent
              </button>
            </td>
          </tr>
        </table>
      </div>
      <div class="mt-3">
        <button
          @click="prevPage(currentPage)"
          :disabled="isDisabled"
          class="uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          &laquo; Prev
        </button>

        <button
          @click="nextPage(currentPage)"
          class="float-right uppercase shadow ml-3 hover:bg-blue-600 bg-blue-900 focus:shadow-outline focus:outline-none text-white text-xs font-bold py-3 px-8 rounded"
        >
          Next &raquo;
        </button>
      </div>
    </div>
  </main>
</template>

<script>
import Loading from "./general-components/Loading.vue";
import { mapState, mapGetters, mapActions } from "vuex";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      isLoading: false,
      search: "",
      accounts: null,
      currentPage: 1,
      totalPages: 0,
      statusSort: "all",
      fraudulentFlag: false,
      notFraudulentFlag: false,
      numberOfItems: 0,
    };
  },
  components: {
    Loading,
  },
  computed: {
    ...mapGetters(["GET_FRAUD"]),
    filterAccounts() {
      if (this.GET_FRAUD.data.items) {
        let k =
          this.GET_FRAUD.data &&
          this.GET_FRAUD.data.items.filter(
            (account) =>
              account.business_id
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              account.business_name
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              account.merchant_name
                .toLowerCase()
                .includes(this.search.toLowerCase()) ||
              account.email.toLowerCase().includes(this.search.toLowerCase()) ||
              account.phone_number
                .toLowerCase()
                .includes(this.search.toLowerCase())
          );

        if (this.statusSort !== "all") {
          return [...k].filter((x) => {
            return x.is_fraud === this.statusSort;
          });
        } else {
          return k;
        }
      } else {
        let k = [];
        return k;
      }
    },
    isDisabled() {
      if (this.currentPage === 1) return true;
    },
    all() {
      return this.GET_FRAUD.data.items;
    },
  },
  filters: {
    truncate: function (text, length, suffix) {
      if (text.length > length) {
        return text.substring(0, length) + suffix;
      } else {
        return text;
      }
    },
  },
  async created() {
    this.isLoading = true;
    let response = await this.$store.dispatch("FETCH_ALL_FRAUD");
    this.accounts = response;
    if (response.data.data) {
      this.isLoading = false;
      this.totalPages = response.headers.Totalpages;
      this.numberOfItems = response.headers.Numberofitems;
    }
    this.isLoading = false;
  },
  methods: {
    ...mapActions([
      "FETCH_ALL_FRAUD",
      "UPDATE_FRAUD_STATUS",
      "FETCH_PAGINATED_FRAUD",
      "FETCH_SINGLE_FRAUD",
      "DOWNLOAD_FRAUD_CSV",
      "FETCH_FRAUD_TYPE",
    ]),
    async fetchAllFraud() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("FETCH_ALL_FRAUD");
        this.isLoading = false;
      } catch (error) {}
    },
    async markAsFraudulent(id, status) {
      let res = confirm("Are you sure you want to perform this operation?");
      this.isLoading = true;
      let payload = [id, status];
      if (res) {
        let res = await this.$store.dispatch("UPDATE_FRAUD_STATUS", payload);
        if (res.status) {
          alert("Status was updated successfully");
          setTimeout(() => {
            this.$router.go();
          }, 200);
        } else {
          alert(res.error);
        }
      }
      this.isLoading = false;
    },
    async prevPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FRAUD",
        currPage - 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage -= 1;
      }
    },
    async nextPage() {
      this.isLoading = true;
      let currPage = this.currentPage;
      let res = await this.$store.dispatch(
        "FETCH_PAGINATED_FRAUD",
        currPage + 1
      );
      if (res.status) {
        this.isLoading = false;
        this.currentPage += 1;
      }
    },
    async handleSearch() {
      this.isLoading = true;
      let res = await this.$store.dispatch("FETCH_SINGLE_FRAUD", this.search);
      console.log(res.status);
      if (!res.success && res.status === undefined) {
        this.isLoading = false;
        alert("Could not find " + this.search);
        console.log(this.isLoading);
        this.isLoading = false;
      } else if (res.status && res.success === undefined) {
        this.isLoading = false;
      }
      this.isLoading = false;
    },

    async downloadCSV() {
      this.isLoading = true;

      let res = await this.$store.dispatch("DOWNLOAD_FRAUD_CSV");

      if (res.status) {
        window.open(res.data, "_blank");
      } else {
        alert("Could not find " + this.search);
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async fraudulent() {
      this.isLoading = true;

      this.fraudulentFlag = true;
      this.notFraudulentFlag = false;

      let res = await this.$store.dispatch("FETCH_FRAUD_TYPE", true);

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },
    async notFraudulent() {
      this.isLoading = true;
      this.notFraudulentFlag = true;
      this.fraudulentFlag = false;

      let res = await this.$store.dispatch("FETCH_FRAUD_TYPE", false);

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },

    async fetchAll() {
      this.isLoading = true;
      this.notFraudulentFlag = false;
      this.fraudulentFlag = false;

      let res = await this.$store.dispatch("FETCH_ALL_FRAUD");

      if (res.status) {
      } else {
        alert("Could not find any record");
        // console.log(res.data);
      }
      this.isLoading = false;
    },
  },
};
</script>

<style scoped>
table {
  border-collapse: separate;
  border-spacing: 0;
  min-width: 350px;
}
table tr th:last-child {
  border-right: 1px solid rgb(241, 241, 241);
}
table tr th,
table tr td {
  /* border-right: 1px solid #bbb; */
  border-bottom: 1px solid rgb(238, 238, 238);
  padding: 18px;
}
table tr th:first-child,
table tr td:first-child {
  /* border-right: 1px solid rgb(223, 223, 223); */
  border-left: 1px solid rgb(241, 241, 241);
}
table tr th {
  background: rgb(255, 255, 255);
  border-top: 1px solid rgb(241, 241, 241);
  text-align: left;
  position: sticky;
  top: 0;
}
table tr td {
  background: #f0f6fd;
}
/* top-left border-radius */
table tr:first-child th:first-child {
  border-top-left-radius: 6px;
}

/* top-right border-radius */
table tr:first-child th:last-child {
  border-top-right-radius: 6px;
}

/* bottom-left border-radius */
table tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

/* bottom-right border-radius */
table tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}
</style>
